<section class="section faq-area bg-gray ptb_100">
    <div class="container">
        <div class="row">
            <div class="col-12 col-md-6 col-lg-4">
                <!-- Single Faq -->
                <div class="single-faq bg-white px-4 py-3">
                    <div class="media">
                        <img class="rounded-circle avatar-sm" src="assets/img/avatar_1.png" alt="">
                        <div class="media-body align-self-center ml-3">
                            <h5>How can I install this app?</h5>
                        </div>
                    </div>
                    <p class="mt-3">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Repellat nisi, qui ipsa magni itaque. Corporis eaque sit magnam maxime quam.</p>
                </div>
            </div>
            <div class="col-12 col-md-6 col-lg-4">
                <!-- Single Faq -->
                <div class="single-faq bg-white px-4 py-3">
                    <div class="media">
                        <img class="rounded-circle avatar-sm" src="assets/img/avatar_2.png" alt="">
                        <div class="media-body align-self-center ml-3">
                            <h5>What about the event?</h5>
                        </div>
                    </div>
                    <p class="mt-3">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Repellat nisi, qui ipsa magni itaque. Corporis eaque sit magnam maxime quam.</p>
                </div>
            </div>
            <div class="col-12 col-md-6 col-lg-4">
                <!-- Single Faq -->
                <div class="single-faq bg-white px-4 py-3">
                    <div class="media">
                        <img class="rounded-circle avatar-sm" src="assets/img/avatar_3.png" alt="">
                        <div class="media-body align-self-center ml-3">
                            <h5>This app is not working?</h5>
                        </div>
                    </div>
                    <p class="mt-3">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Repellat nisi, qui ipsa magni itaque. Corporis eaque sit magnam maxime quam.</p>
                </div>
            </div>
            <div class="col-12 col-md-6 col-lg-4">
                <!-- Single Faq -->
                <div class="single-faq bg-white px-4 py-3">
                    <div class="media">
                        <img class="rounded-circle avatar-sm" src="assets/img/avatar_4.png" alt="">
                        <div class="media-body align-self-center ml-3">
                            <h5>How can I get more?</h5>
                        </div>
                    </div>
                    <p class="mt-3">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Repellat nisi, qui ipsa magni itaque. Corporis eaque sit magnam maxime quam.</p>
                </div>
            </div>
            <div class="col-12 col-md-6 col-lg-4">
                <!-- Single Faq -->
                <div class="single-faq bg-white px-4 py-3">
                    <div class="media">
                        <img class="rounded-circle avatar-sm" src="assets/img/avatar_5.png" alt="">
                        <div class="media-body align-self-center ml-3">
                            <h5>Can I opt out from this?</h5>
                        </div>
                    </div>
                    <p class="mt-3">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Repellat nisi, qui ipsa magni itaque. Corporis eaque sit magnam maxime quam.</p>
                </div>
            </div>
            <div class="col-12 col-md-6 col-lg-4">
                <!-- Single Faq -->
                <div class="single-faq bg-white px-4 py-3">
                    <div class="media">
                        <img class="rounded-circle avatar-sm" src="assets/img/avatar_1.png" alt="">
                        <div class="media-body align-self-center ml-3">
                            <h5>How can I install this app?</h5>
                        </div>
                    </div>
                    <p class="mt-3">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Repellat nisi, qui ipsa magni itaque. Corporis eaque sit magnam maxime quam.</p>
                </div>
            </div>
            <div class="col-12 col-md-6 col-lg-4">
                <!-- Single Faq -->
                <div class="single-faq bg-white px-4 py-3">
                    <div class="media">
                        <img class="rounded-circle avatar-sm" src="assets/img/avatar_2.png" alt="">
                        <div class="media-body align-self-center ml-3">
                            <h5>How can I install this app?</h5>
                        </div>
                    </div>
                    <p class="mt-3">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Repellat nisi, qui ipsa magni itaque. Corporis eaque sit magnam maxime quam.</p>
                </div>
            </div>
            <div class="col-12 col-md-6 col-lg-4">
                <!-- Single Faq -->
                <div class="single-faq bg-white px-4 py-3">
                    <div class="media">
                        <img class="rounded-circle avatar-sm" src="assets/img/avatar_3.png" alt="">
                        <div class="media-body align-self-center ml-3">
                            <h5>How can I install this app?</h5>
                        </div>
                    </div>
                    <p class="mt-3">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Repellat nisi, qui ipsa magni itaque. Corporis eaque sit magnam maxime quam.</p>
                </div>
            </div>
            <div class="col-12 col-md-6 col-lg-4">
                <!-- Single Faq -->
                <div class="single-faq bg-white px-4 py-3">
                    <div class="media">
                        <img class="rounded-circle avatar-sm" src="assets/img/avatar_4.png" alt="">
                        <div class="media-body align-self-center ml-3">
                            <h5>How can I install this app?</h5>
                        </div>
                    </div>
                    <p class="mt-3">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Repellat nisi, qui ipsa magni itaque. Corporis eaque sit magnam maxime quam.</p>
                </div>
            </div>
        </div>
    </div>
</section>