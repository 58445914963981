import { Component, OnInit } from '@angular/core';


declare type Game = {
  title: string;
  img: string;
  description: string;
  google_url: string;
  apple_url: string;
}

@Component({
  selector: 'app-blog-two-column',
  templateUrl: './blog-two-column.component.html',
  styleUrls: ['./blog-two-column.component.css']
})


export class BlogTwoColumnComponent implements OnInit {

  games: Game[] = []

  constructor() { }

  
  ngOnInit(): void {
    this.games.push(
    {
      title: '2048 MERGE BLOCKS',
      description: 'This is a challenging and totally free 2048 merge puzzle game to exercise your brain!',
      apple_url: 'https://apps.apple.com/us/app/2048-merge-block-puzzle-game/id1663643678',
      google_url: 'https://play.google.com/store/apps/details?id=com.manitoogames.dropnumber',
      img: 'mb'
    },
    {
      title: 'PIPE CONNECT - CONNECT THE DOTS',
      description: 'Connect matching colors using pipes to create a path. Pair all colors while covering the entire board to solve each puzzle!',
      apple_url: 'https://apps.apple.com/us/app/pipe-connect-puzzle/id1635342814',
      google_url: 'https://play.google.com/store/apps/details?id=com.manitoogames.pipeconnect',
      img: 'pc'
    },
    {
      title: 'COLOR SORT - WATER PUZZLE GAME',
      description: 'A Colour sorting puzzle game! A fun, addictive, yet challenging game. Multiple levels available for your enjoyment.',
      apple_url: 'https://apps.apple.com/us/app/id1605670313',
      google_url: 'https://play.google.com/store/apps/details?id=com.manitoogames.watersortpuzzle',
      img: 'wsp'
    },
    {
      title: 'MANITOO QUIZ',
      description: 'Play to enhance your knowledge, challenge your friends and reach incredible levels!',
      apple_url: 'https://apps.apple.com/us/app/id1518514408',
      google_url: 'https://play.google.com/store/apps/details?id=com.manitoo',
      img: 'quiz'
    },
    
    )
  }

}
