<section id="home" class="section welcome-area bg-overlay bg-inherit h-100vh">
    <div class="container">
        <div class="row justify-content-center">
            <!-- Welcome Intro Start -->
            <div class="col-12 col-md-10 col-lg-8">
                <div class="welcome-intro text-center">
                    <h1 class="text-white">Get all your things done with Appo</h1>
                    <p class="text-white my-4">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Tempore doloribus illum quas ipsam quia, amet accusantium accusamus exercitationem quis, nihil numquam commodi esse maiores quisquam. Autem molestias eum aliquid sunt.</p>
                    <a href="#" class="btn btn-bordered-white"><span>Get Started</span></a>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-12 col-sm-8 col-md-5 mx-auto">
                <!-- Seoul Thumb -->
                <div class="seoul-thumb text-center pt-5">
                    <img src="assets/img/welcome_mockup.png" alt="">
                </div>
            </div>
        </div>
    </div>
</section>