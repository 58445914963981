<section class="section download-area ptb_100">
    <!-- Shapes Container -->
    <div class="shapes-container d-none d-sm-block">
        <div class="shape-2"></div>
        <div class="shape-3"></div>
    </div>
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-12 col-md-10 col-lg-8">
                <!-- Download Text -->
                <div class="download-text text-center">
                    <h2>Download Appo</h2>
                    <p class="my-3">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Itaque at harum quam explicabo. Aliquam optio, delectus, dolorem quod neque eos totam. Delectus quae animi tenetur voluptates doloribus commodi dicta modi aliquid deserunt, quis maiores nesciunt autem, aperiam natus. Dolores, dolorum?</p>
                    <p class="d-none d-sm-block my-3">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Similique dolor ut iusto vitae autem neque eum ipsam, impedit asperiores vel cumque laborum dicta repellendus inventore voluptatibus et explicabo nobis unde.</p>
                    <!-- Store Buttons -->
                    <div class="button-group store-buttons">
                        <a href="#" class="btn btn-bordered">
                            <i class="icofont icofont-brand-android-robot dsp-tc"></i>
                            <p class="dsp-tc">GET IT ON
                                <br> <span>Google Play</span></p>
                        </a>
                        <a href="#" class="btn btn-bordered">
                            <i class="icofont icofont-brand-apple dsp-tc"></i>
                            <p class="dsp-tc">AVAILABLE ON
                                <br> <span>Apple Store</span></p>
                        </a>
                        <a href="#" class="btn btn-bordered d-none d-lg-inline-block">
                            <i class="icofont-brand-windows dsp-tc"></i>
                            <p class="dsp-tc">AVAILABLE ON
                                <br> <span>Windows Store</span></p>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>