import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { BlogTwoColumnComponent } from './components/blogs/blog-two-column/blog-two-column.component';
import { PrivacyComponent } from './components/blogs/privacy/privacy.component';


const routes: Routes = [
  {path: '', component: BlogTwoColumnComponent},
  {path: 'privacy', component: PrivacyComponent}
  //{path: '**', redirectTo: ''},

 
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
