<section class="section skills-area bg-gray ptb_100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-12 col-lg-6 mb-5 mb-lg-0">
                <div class="skills-content">
                    <h2>We care about your Business</h2>
                    <p class="d-none d-sm-block my-3">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Sit, eaque. Assumenda modi debitis voluptatem eum delectus. Accusantium voluptate, facilis expedita fuga delectus explicabo vel, dicta ipsam! Repudiandae commodi neque atque asperiores, dolore. Deleniti sint aperiam officiis fuga quia laborum reprehenderit voluptates suscipit assumenda, accusamus aliquam soluta nesciunt facere, amet nihil?</p>
                    <p class="d-block d-sm-none my-3">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Nesciunt error vel autem, quibusdam voluptatibus nobis voluptatum, recusandae consectetur, quas numquam dolorem deserunt dignissimos est! Deleniti delectus sint nostrum reiciendis? Ratione.</p>
                    <h6 class="fw-5 mb-2">Technologies We Use</h6>
                    <a class="op-5 pr-3" href="#"><i class="fab fa-html5 fa-lg"></i></a>
                    <a class="op-5 pr-3" href="#"><i class="fab fa-css3 fa-lg"></i></a>
                    <a class="op-5 pr-3" href="#"><i class="fab fa-bootstrap fa-lg"></i></a>
                    <a class="op-5 pr-3" href="#"><i class="fab fa-sass fa-lg"></i></a>
                    <a class="op-5" href="#"><i class="fas fa-rss fa-lg"></i></a>
                </div>
            </div>
            <div class="col-12 col-md-8 col-lg-5 mx-lg-auto">
                <!-- Progress Bars -->
                <div class="progress-bars">
                    <!-- Progress Label -->
                    <div class="progress-label">
                        <span class="text-dark">HTML/CSS</span>
                    </div>
                    <!-- Progress -->
                    <div class="progress mb-3">
                        <div class="progress-bar progress-bar-striped progress-bar-animated" role="progressbar" aria-valuenow="68" aria-valuemin="0" aria-valuemax="100" style="width: 68%"></div>
                    </div>
                    <!-- Progress Label -->
                    <div class="progress-label">
                        <span class="text-dark">Bootstrap</span>
                    </div>
                    <!-- Progress -->
                    <div class="progress mb-3">
                        <div class="progress-bar progress-bar-striped progress-bar-animated" role="progressbar" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100" style="width: 75%"></div>
                    </div>
                    <!-- Progress Label -->
                    <div class="progress-label">
                        <span class="text-dark">Javascipt</span>
                    </div>
                    <!-- Progress -->
                    <div class="progress mb-3">
                        <div class="progress-bar progress-bar-striped progress-bar-animated" role="progressbar" aria-valuenow="82" aria-valuemin="0" aria-valuemax="100" style="width: 82%"></div>
                    </div>
                    <!-- Progress Label -->
                    <div class="progress-label">
                        <span class="text-dark">PhP</span>
                    </div>
                    <!-- Progress -->
                    <div class="progress">
                        <div class="progress-bar progress-bar-striped progress-bar-animated" role="progressbar" aria-valuenow="95" aria-valuemin="0" aria-valuemax="100" style="width: 95%"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>