<section id="home" class="section welcome-area bg-inherit d-flex align-items-center overlay-primary jarallax h-100vh pt-0" data-jarallax-video="https://www.youtube.com/watch?v=hs1HoLs4SD0">
    <div class="container">
        <div class="row align-items-center justify-content-center">
            <!-- Welcome Intro Start -->
            <div class="col-12 col-md-10 col-lg-8">
                <div class="welcome-intro text-center">
                    <h1 class="text-white">Creative way to Land your page</h1>
                    <p class="text-white mt-4 mb-5">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Officiis nihil facilis aperiam, voluptatum asperiores temporibus accusamus saepe nostrum commodi excepturi hic veritatis dolores perferendis aspernatur dolore quis aut libero impedit sunt cum sed beatae corporis. Dolor non, autem veniam libero.</p>
                    <div class="input-group">
                        <input type="email" class="form-control" placeholder="Enter Your Email" aria-label="Recipient's username">
                        <div class="input-group-append">
                            <button class="btn btn-bordered-white" type="submit">Submit</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>