<section class="section about-app-area ptb_100">
    <div class="shapes-container">
        <div class="shape-1"></div>
    </div>
    <div class="container">
        <div class="row align-items-center">
            <div class="col-12 col-lg-6">
                <!-- About Text -->
                <div class="about-text">
                    <!-- Headings -->
                    <div class="headings d-flex align-items-center mb-4">
                        <span class="text-uppercase d-none d-sm-block">About</span>
                        <h2 class="text-capitalize">Easy To Manage All Your Data By Appo</h2>
                    </div>
                    <p class="my-3">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Itaque at harum quam explicabo. Aliquam optio, delectus, dolorem quod neque eos totam. Delectus quae animi tenetur voluptates doloribus commodi dicta modi aliquid deserunt, quis maiores nesciunt autem, aperiam natus. Dolores, dolorum?</p>
                    <p class="d-none d-sm-block my-3">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Similique dolor ut iusto vitae autem neque eum ipsam, impedit asperiores vel cumque laborum dicta repellendus inventore voluptatibus et explicabo nobis unde.</p>
                    <!-- Store Buttons -->
                    <div class="button-group store-buttons">
                        <a href="#" class="btn btn-bordered">
                            <i class="icofont icofont-brand-android-robot dsp-tc"></i>
                            <p class="dsp-tc">GET IT ON
                                <br> <span>Google Play</span></p>
                        </a>
                        <a href="#" class="btn btn-bordered">
                            <i class="icofont icofont-brand-apple dsp-tc"></i>
                            <p class="dsp-tc">AVAILABLE ON
                                <br> <span>Apple Store</span></p>
                        </a>
                    </div>
                </div>
            </div>
            <div class="col-12 col-lg-6">
                <!-- About Thumb -->
                <div class="about-thumb text-center d-none d-lg-block">
                    <img src="assets/img/about.png" alt="">
                </div>
            </div>
        </div>
    </div>
</section>