<section id="home" class="section welcome-area bg-inherit d-flex align-items-center h-100vh">
    <div class="container">
        <div class="row align-items-center">
            <!-- Welcome Intro Start -->
            <div class="col-12 col-md-7 col-lg-6">
                <div class="welcome-intro">
                    <h1 class="text-white">Creative way to Land your page</h1>
                    <p class="text-white my-4">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Temporibus commodi, voluptate quaerat iure quidem expedita eos a blanditiis sint modi est error veniam facere eum at doloribus amet, nobis ut.</p>
                    <a href="#" class="btn btn-bordered text-white"><span>Get Started</span></a>
                </div>
            </div>
            <div class="col-12 col-md-5 mx-auto pt-4 pt-md-0">
                <!-- Welcome Thumb -->
                <div class="welcome-thumb">
                    <img src="assets/img/london_thumb.png" alt="">
                </div>
            </div>
        </div>
    </div>
</section>