<section id="features" class="section features-area ptb_100">
    <div class="shapes-container">
        <div class="shape bg-shape"></div>
    </div>
    <div class="container">
        <div class="row align-items-center">
            <div class="col-12 col-md-6 col-lg-6">
                <div class="features-text">
                    <!-- Headings -->
                    <div class="headings d-flex align-items-center mb-4">
                        <span class="text-uppercase d-none d-sm-block">Features</span>
                        <h2 class="text-capitalize">Built In Powerful Features you Can't Miss</h2>
                    </div>
                    <p class="my-3">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Itaque at harum quam explicabo. Aliquam optio, delectus, dolorem quod neque eos totam. Delectus quae animi tenetur voluptates doloribus commodi dicta modi aliquid deserunt, quis maiores nesciunt autem, aperiam natus. Dolores, dolorum?</p>
                    <!-- Counter List -->
                    <div class="counter-list">
                        <ul>
                            <li>
                                <!-- Single Counter -->
                                <div class="single-counter px-4 py-3 text-center">
                                    <!-- Counter Icon -->
                                    <div class="counter-icon">
                                        <i class="icofont-repair"></i>
                                    </div>
                                    <!-- Counter Text -->
                                    <div class="counter-text">
                                        <span class="counter d-inline-block mt-3 mb-2">2350</span>
                                        <h5>Users</h5>
                                    </div>
                                </div>
                            </li>
                            <li>
                                <!-- Single Counter -->
                                <div class="single-counter px-4 py-3 text-center">
                                    <!-- Counter Icon -->
                                    <div class="counter-icon">
                                        <i class="icofont-heart-alt"></i>
                                    </div>
                                    <!-- Counter Text -->
                                    <div class="counter-text">
                                        <span class="counter d-inline-block mt-3 mb-2">1895</span>
                                        <h5>Acounts</h5>
                                    </div>
                                </div>
                            </li>
                            <li>
                                <!-- Single Counter -->
                                <div class="single-counter px-4 py-3 text-center">
                                    <!-- Counter Icon -->
                                    <div class="counter-icon">
                                        <i class="icofont-beard"></i>
                                    </div>
                                    <!-- Counter Text -->
                                    <div class="counter-text">
                                        <span class="counter d-inline-block mt-3 mb-2">1580</span>
                                        <h5>Developers</h5>
                                    </div>
                                </div>
                            </li>
                            <li>
                                <!-- Single Counter -->
                                <div class="single-counter px-4 py-3 text-center">
                                    <!-- Counter Icon -->
                                    <div class="counter-icon">
                                        <i class="icofont-safety"></i>
                                    </div>
                                    <!-- Counter Text -->
                                    <div class="counter-text">
                                        <span class="counter d-inline-block mt-3 mb-2">1358</span>
                                        <h5>Download</h5>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="col-12 col-md-6 col-lg-5 offset-lg-1">
                <!-- Featured Items -->
                <div class="featured-items">
                    <ul>
                        <li>
                            <!-- Single Features Item -->
                            <div class="single-features media p-3">
                                <!-- Features Title -->
                                <div class="features-title mr-3">
                                    <img src="assets/img/icon_1.svg" alt="">
                                </div>
                                <!-- Features Text -->
                                <div class="features-text media-body">
                                    <h3>Simple &amp; Light</h3>
                                    <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Laboriosam, adipisci.</p>
                                </div>
                            </div>
                        </li>
                        <li>
                            <!-- Single Features Item -->
                            <div class="single-features media p-3">
                                <!-- Features Title -->
                                <div class="features-title mr-3">
                                    <img src="assets/img/icon_2.svg" alt="">
                                </div>
                                <!-- Features Text -->
                                <div class="features-text media-body">
                                    <h3>Creative Design</h3>
                                    <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Laboriosam, adipisci.</p>
                                </div>
                            </div>
                        </li>
                        <li>
                            <!-- Single Features Item -->
                            <div class="single-features media p-3">
                                <!-- Features Title -->
                                <div class="features-title mr-3">
                                    <img src="assets/img/icon_3.svg" alt="">
                                </div>
                                <!-- Features Text -->
                                <div class="features-text media-body">
                                    <h3>Retina Ready</h3>
                                    <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Laboriosam, adipisci.</p>
                                </div>
                            </div>
                        </li>
                        <li>
                            <!-- Single Features Item -->
                            <div class="single-features media p-3">
                                <!-- Features Title -->
                                <div class="features-title mr-3">
                                    <img src="assets/img/icon_4.svg" alt="">
                                </div>
                                <!-- Features Text -->
                                <div class="features-text media-body">
                                    <h3>Unlimited Features</h3>
                                    <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Laboriosam, adipisci.</p>
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</section>