<div class="inner-wrapper d-flex flex-column align-items-center justify-content-between p-4">
    <a href="index.html">
        <img src="assets/img/inner_logo.png" alt="">
    </a>
    <!-- ***** Forgot Area Start ***** -->
    <div class="forgot-area">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-12 col-sm-8 col-md-6 col-lg-5 mb-5 mb-md-0 mx-auto pt-4 pt-md-0">
                    <img src="assets/img/forgot_thumb.png" alt="">
                </div>
                <div class="col-12 col-md-6 col-lg-6 mb-5 mb-md-0">
                    <h2 class="text-primary">Forgot your Password?</h2>
                    <p class="mt-3 mb-4">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Architecto beatae, asperiores tempore repudiandae saepe aspernatur unde voluptate sapiente quia ex.</p>
                    <div class="input-group reset-password">
                        <input type="email" class="form-control" placeholder="Enter your Email">
                        <div class="input-group-append mt-3 mt-sm-0">
                            <button type="button" class="btn btn-primary">Reset</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- ***** Forgot Area End ***** -->
    <div class="footer-bottom">
        <!-- Copyright Area -->
        <div class="copyright-area border-0 pt-4 pt-md-0">
            <p>Made with <i class="icofont-heart-alt"></i> By <a href="#">Theme Land</a></p>
        </div>
    </div>
</div>