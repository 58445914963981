<div class="blog">
  <app-scrollup></app-scrollup>
  <div class="all-area">
    <app-header-two></app-header-two>
    <app-breadcrumb-blog-two-column></app-breadcrumb-blog-two-column>
    <section id="blog" class="section blog-area ptb_100">
      <div class="container">
        <div class="row">

          <div *ngFor="let game of games" class="col-12 col-md-6">
            <!-- Single Blog -->
            <div class="single-blog res-margin">
              <!-- Blog Thumb -->
              <div class="blog-thumb">
                <a href="#"><img src="assets/img/games/{{game.img}}.png" alt="" /></a>
              </div>
              <!-- Blog Content -->
              <div class="blog-content p-4">
                <h3 class="blog-title my-3">
                  <a href="#">{{game.title}}</a>
                </h3>
                <p>
                 {{game.description}}
                </p>
              </div>

              <div class="blog-content p-4 d-flex justify-content-center">
                <a href="{{game.apple_url}}"
                  ><img
                    src="assets/img/appstore.png"
                    alt="appstore"
                    class="mx-3"
                /></a>
                <a
                  href="{{game.google_url}}"
                  ><img
                    class="ml-20 store"
                    src="assets/img/play-store.png"
                    alt="play-store"
                /></a>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <!-- Pagination
                        <app-team></app-team>
                         -->
          </div>
        </div>
      </div>
    </section>
    <app-footer></app-footer>
  </div>
</div>
