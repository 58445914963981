<div class="inner-wrapper d-flex flex-column align-items-center p-4">
    <a href="index.html">
        <img src="assets/img/inner_logo.png" alt="">
    </a>
    <!-- ***** Error Area Start ***** -->
    <div class="error-area">
        <div class="container">
            <div class="row">
                <div class="col-12 col-sm-10 col-md-8 col-lg-6 mx-auto mb-5">
                    <img src="assets/img/404.gif" alt="">
                </div>
            </div>
            <div class="row">
                <div class="col-12 col-md-10 col-lg-8 mx-auto text-center">
                    <h1>Error 404</h1>
                    <p class="mt-3 mb-4">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Architecto beatae, asperiores tempore repudiandae saepe aspernatur unde voluptate sapiente quia ex.</p>
                    <a href="index.html" class="btn btn-primary"><span>Go Home</span></a>
                </div>
            </div>
        </div>
    </div>
</div>