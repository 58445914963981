<div class="blog">
    <app-scrollup></app-scrollup>
    <div class="all-area">
        <div id="appo-header" class="main-header-area nav-white">
            <div class="container-fluid">
                <nav class="navbar navbar-expand-md navbar-light">
                    <!-- Logo -->
                    <a class="navbar-brand" href="/index.html">
                        <img class="logo" src="assets/img/logo_manitoo.png" alt="">
                    </a>
                    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#appo-menu">
                        <span class="navbar-toggler-icon"></span>
                    </button>
                </nav>
            </div>
        </div>
        <section class="section breadcrumb-area overlay-dark d-flex align-items-center">
            <div class="container">
                <div class="row">
                    <div class="col-12">
                        <!-- Breamcrumb Content -->
                        <div class="breadcrumb-content d-flex flex-column align-items-center text-center">
                            <h3 class="text-white">Privacy Policy</h3>
                            <ol class="breadcrumb">
                            </ol>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section id="blog" class="section blog-area ptb_100">
            <div class="container">
                <div class="row p-3">

                    This privacy policy governs your use of the software application MANITOO ("Application") for mobile
                    devices that was created by&nbsp;Manitoo Games Inc.&nbsp;The Application is&nbsp;quiz game for
                    mobile platforms&nbsp;

                    <div class="privacy-text mt-4 mx-2 text-center">
                        <h3>What information does the Application obtain and how is it used?</h3>
                        <p class="mt-3"><strong>User Provided Information</strong></p>

                        <p class="my-1">
                            The Application obtains the information you provide when you download and register the
                            Application.&nbsp;Registration with us is mandatory in order to improve user experience.
                            Scores achieved by the user will be uploaded to our servers for the creation of the game's
                            leaderboards.
                            When you register with us and use the Application, you generally provide&nbsp;(a) name/nickname        
                        </p>

                        <p class="mt-3"><strong>Automatically Collected Information</strong></p>

                        <p class="my-1">
                            In addition, the Application may collect certain information automatically, including, but not
                    limited to, the type of mobile device you use, your mobile operating system, the country of your SIM
                    card and information about the way you use the Application  
                        </p>

                        <p class="mt-3"><strong>Does the Application collect precise real time location information of the device?</strong></p>

                        <p class="my-1">
                            IThis Application does not collect precise information about the location of your mobile
                            device
                        </p>
                        
                        This Application does not collect precise information about the location of your mobile
                        device.&nbsp;
                        &nbsp;
                        <h4>Do third parties see and/or have access to information obtained by the Application?</h4>
                        No data is sent to third party data.
                        We may disclose User Provided and Automatically Collected Information:
                        <ul>
                            <li>
                                as required by law, such as to comply with a subpoena, or similar legal process;
                            </li>
                            <li>
                                when we believe in good faith that disclosure is necessary to protect our rights, protect
                                your safety or the safety of others, investigate fraud, or respond to a&nbsp;government
                                request;
                            </li>
                            <li>
                                with our trusted services providers who work on our behalf, do not have an&nbsp;independent
                                use of the information we disclose to them, and have agreed to adhere&nbsp;to the rules set
                                forth in this privacy statement.
                            </li>
                            <li>
                                if&nbsp;Manitoo Games Inc.&nbsp;is involved in a merger, acquisition, or sale of all or
                                a&nbsp;portion of its assets, you will be notified via email and/or a prominent notice on
                                our Web site of any change in ownership or uses of this information, as well as any choices
                                you may have regarding this information.
                            </li>
                        </ul>
    
                        <h4>Advertising</h4>
    
                        Our FREE apps have ads, we are using Google Admob and Facebook Audience Network as ads providers,
                        with third party services that may collect information about you.
                        We may from time to time promote other apps of the company or other company within our apps.
                        For now we have no way to disable Ads Personalization from AdMob, as we use third party AdMob ane,
                        BUT you can do it yourself, you can disable Ads Personalization by entering this link-
                        adssettings.google.com and disable the Ads Personalization.
                        By turning off Ads Personalization:
                        We may disclose User Provided and Automatically Collected Information:
                        <ul>
                            <li>
                                You’ll still see ads, but they’ll be less useful to you
                            </li>
                            <li>
                                You’ll no longer be able to block or mute some ads;
                            </li>
                        </ul>
    
                        &nbsp;
                        <h4>What are my opt-out rights?</h4>
    
                        You can stop all collection of information by the Application easily by uninstalling the
                        Application. You may use the standard uninstall processes as may be available as part of your mobile
                        device or via the mobile application marketplace or network. You can also request to opt-out via
                        email, at info@manitoogames.com.
                        &nbsp;
                        <h4>
                            <strong>Data Retention Policy, Managing Your Information</strong>
                        </h4>
                        We will retain User Provided data for as long as you use the Application and for a reasonable time
                        thereafter. We will retain Automatically Collected information for up to 24 months&nbsp;and
                        thereafter may store it in aggregate. If you'd like us to delete User Provided Data that you have
                        provided via the Application, please contact us at&nbsp;info@manitoogames.com&nbsp;and we will
                        respond in a reasonable time. Please note that some or all of the User Provided Data may be required
                        in order for the Application to function properly.
                        &nbsp;
                        <h4>
                            <strong>Children</strong>
                        </h4>
    
                        We do not use the Application to knowingly solicit data from or market to children under the age of
                        13. If a parent or guardian becomes aware that his or her child has provided us with information
                        without their consent, he or she should contact us at&nbsp;info@manitoogames.com. We will delete
                        such information from our files within a reasonable time.
                        <h4>&nbsp;</h4>
                        <h4>
                            <strong>Security</strong>
                        </h4>
    
                        We are concerned about safeguarding the confidentiality of your information. We provide physical,
                        electronic, and procedural safeguards to protect information we process and maintain. For example,
                        we limit access to this information to authorized employees and contractors who need to know that
                        information in order to operate, develop or improve our Application. Please be aware that, although
                        we endeavor provide reasonable security for information we process and maintain, no security system
                        can prevent all potential security breaches.
                        &nbsp;
                        <h4>
                            <strong>Changes</strong>
                        </h4>
                        This Privacy Policy may be updated from time to time for any reason. We will notify you of any
                        changes to our Privacy Policy by posting the new Privacy Policy&nbsp;here&nbsp;and&nbsp;informing
                        you via email or text message. You are advised to consult this Privacy Policy regularly for any
                        changes, as continued use is deemed approval of all changes. You can check the history of this
                        policy by clicking here.
                        &nbsp;
                        <h4>
                            <strong>Your Consent</strong>
                        </h4>
                        By using the Application, you are consenting to our processing of your information as set forth in
                        this Privacy Policy now and as amended by us. &quot;Processing," means using cookies on a
                        computer/hand held device or using or touching information in any way, including, but not limited
                        to, collecting, storing, deleting, using, combining and disclosing information, all of which
                        activities will take place in the United States. If you reside outside the United States&nbsp;your
                        information will be transferred, processed and stored there under United States&nbsp;privacy
                        standards.&nbsp;
                        &nbsp;
                        <h4>Contact us</h4>
                        If you have any questions regarding privacy while using the Application, or have questions about our
                        practices, please contact us via email at&nbsp;info@manitoogames.com.
                    </div>

                </div>

            </div>
        </section>
        <app-footer></app-footer>
    </div>
</div>