<section id="blog" class="section blog-area ptb_100">
    <div class="container">
        <div class="row justify-content-center">
            <!-- Section Heading -->
            <div class="col-12 col-md-10 col-lg-6">
                <!-- Section Heading -->
                <div class="section-heading text-center">
                    <h2 class="text-capitalize">Latest from Blog</h2>
                    <p class="d-none d-sm-block mt-4">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Laborum obcaecati dignissimos quae quo ad iste ipsum officiis deleniti asperiores sit.</p>
                    <p class="d-block d-sm-none mt-4">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Laborum obcaecati.</p>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-12 col-md-6 col-lg-4">
                <!-- Single Blog -->
                <div class="single-blog res-margin">
                    <!-- Blog Thumb -->
                    <div class="blog-thumb">
                        <a href="#"><img src="assets/img/blog_1.jpg" alt=""></a>
                    </div>
                    <!-- Blog Content -->
                    <div class="blog-content p-4">
                        <!-- Meta Info -->
                        <ul class="meta-info d-flex justify-content-between">
                            <li>By <a href="#">Anna Sword</a></li>
                            <li><a href="#">Feb 05, 2019</a></li>
                        </ul>
                        <!-- Blog Title -->
                        <h3 class="blog-title my-3"><a href="#">Blog Title Here</a></h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ratione obcaecati, animi vitae recusandae, praesentium quae.</p>
                        <a href="#" class="blog-btn mt-3">Read More</a>
                    </div>
                </div>
            </div>
            <div class="col-12 col-md-6 col-lg-4">
                <!-- Single Blog -->
                <div class="single-blog res-margin">
                    <!-- Blog Thumb -->
                    <div class="blog-thumb">
                        <a href="#"><img src="assets/img/blog_2.jpg" alt=""></a>
                    </div>
                    <!-- Blog Content -->
                    <div class="blog-content p-4">
                        <!-- Meta Info -->
                        <ul class="meta-info d-flex justify-content-between">
                            <li>By <a href="#">Jassica William</a></li>
                            <li><a href="#">Feb 05, 2019</a></li>
                        </ul>
                        <!-- Blog Title -->
                        <h3 class="blog-title my-3"><a href="#">Blog Title Here</a></h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ratione obcaecati, animi vitae recusandae, praesentium quae.</p>
                        <a href="#" class="blog-btn mt-3">Read More</a>
                    </div>
                </div>
            </div>
            <div class="col-12 col-md-6 col-lg-4">
                <!-- Single Blog -->
                <div class="single-blog">
                    <!-- Blog Thumb -->
                    <div class="blog-thumb">
                        <a href="#"><img src="assets/img/blog_3.jpg" alt=""></a>
                    </div>
                    <!-- Blog Content -->
                    <div class="blog-content p-4">
                        <!-- Meta Info -->
                        <ul class="meta-info d-flex justify-content-between">
                            <li>By <a href="#">John Doe</a></li>
                            <li><a href="#">Mar 05, 2019</a></li>
                        </ul>
                        <!-- Blog Title -->
                        <h3 class="blog-title my-3"><a href="#">Blog Title Here</a></h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ratione obcaecati, animi vitae recusandae, praesentium quae.</p>
                        <a href="#" class="blog-btn mt-3">Read More</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>